// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-list-template-project-list-template-js": () => import("./../src/templates/project-list-template/project-list-template.js" /* webpackChunkName: "component---src-templates-project-list-template-project-list-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fareshare-js": () => import("./../src/pages/fareshare.js" /* webpackChunkName: "component---src-pages-fareshare-js" */),
  "component---src-pages-gov-financial-app-js": () => import("./../src/pages/gov-financial-app.js" /* webpackChunkName: "component---src-pages-gov-financial-app-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-savr-js": () => import("./../src/pages/savr.js" /* webpackChunkName: "component---src-pages-savr-js" */)
}

